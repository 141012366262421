import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  getQuestions,
  postQuestion,
  listen,
} from './components/firebase/Firebase';
import { FirebaseContext } from './components/firebase/FirebaseContext';

ReactDOM.render(
  <FirebaseContext.Provider value={{ getQuestions, postQuestion, listen }}>
    <App />
  </FirebaseContext.Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
